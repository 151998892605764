.main-footer{
  margin-top: 120px;
}

.main-footer__content{
  align-items: center;
  border-top: 1px solid var(--color-gray);
  display: flex;
  font-size: 0.875rem;
  justify-content: space-between;
  padding: 21px 12px;
}

.main-footer__menu{
  align-items: center;
  display: flex;
  gap: 9px;
}

.main-footer__menu-item{
  list-style: none;
}