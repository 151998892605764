:root{
  --color-black: #2f2f2f;
  --color-white: #fff;
  --color-gray: #858585;
  --color-light-gray: #EDEDED;
  --color-accent: #F7EC0A;
  --color-accent-light: rgba(247, 236, 10, 0.1);
  --font-primary: 'WUS', Arial, Helvetica, sans-serif;
  --font-secondary: 'BAS', Arial, Helvetica, sans-serif;

  --background-page: #fff;
  --background-bento-item: linear-gradient(180deg, rgba(117,60,60,0) 0%, rgba(183,183,183,0.15) 100%);
  --visible-1: inherit;
  --visible-2: none;
}
