/* html5doctor.com Reset v1.6.1 - http://cssreset.com */
html, body, div, span, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, abbr, address, cite, code, del, dfn, em, img, ins, kbd, q, samp, small, strong, sub, sup, var, b, i, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, figcaption, figure, footer, header, hgroup, menu, nav, section, summary, time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
}

body {
  line-height: 1;
}

article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section {
  display: block;
}

nav ul {
  list-style: none;
}

blockquote, q {
  quotes: none;
}

blockquote:before, blockquote:after, q:before, q:after {
  content: none;
}

a {
  margin: 0;
  padding: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
}

ins {
  background-color: #ff9;
  color: #000;
  text-decoration: none;
}

mark {
  background-color: #ff9;
  color: #000;
  font-style: italic;
  font-weight: bold;
}

del {
  text-decoration: line-through;
}

abbr[title], dfn[title] {
  border-bottom: 1px dotted;
  cursor: help;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

hr {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #ccc;
  margin: 1em 0;
  padding: 0;
}

input, select {
  vertical-align: middle;
}

:root {
  --color-black: #000;
  --color-white: #fff;
  --color-gray: #858585;
  --color-light-gray: #EDEDED;
  --color-accent: #F7EC0A;
  --color-accent-light: rgba(247, 236, 10, 0.1);
  --font-primary: "WUS", Arial, Helvetica, sans-serif;
  --font-secondary: "BAS", Arial, Helvetica, sans-serif;
  --background-page: #fff;
  --background-bento-item: linear-gradient(180deg, rgba(117,60,60,0) 0%, rgba(183,183,183,0.15) 100%);
  --visible-1: inherit;
  --visible-2: none;
}

.button {
  background-color: var(--color-accent);
  border-radius: 6px;
  box-shadow: 0 0 0 0 var(--color-black);
  color: var(--color-black);
  display: inline-block;
  font-size: 1.1875rem;
  font-style: italic;
  font-weight: 900;
  line-height: 1;
  padding: 12px 24px;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  transition: box-shadow 0.2s;
}
.button:hover, .button:focus {
  box-shadow: 0 4px 0 0 var(--color-black);
}

.button--dark {
  background-color: var(--color-black);
  border-color: var(--color-black);
  color: var(--color-white);
}

.button--full {
  display: block;
}

html {
  font-size: 100%;
}

body {
  background-color: var(--background-page);
  color: var(--color-black);
  font-family: var(--font-primary);
  font-weight: 400;
  line-height: 1.3;
}

h1, h2, h3 {
  font-weight: 900;
}

strong {
  font-weight: 600;
}

.visually-hidden {
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  width: 1px;
}

.box-content {
  padding-left: 2%;
  padding-right: 2%;
}

.main-title {
  font-size: 2.625rem;
}
@media (min-width: 768px) {
  .main-title {
    font-size: 3rem;
  }
}

.main-subtitle {
  font-size: 1.5rem;
  font-weight: 400;
}
@media (min-width: 768px) {
  .main-subtitle {
    font-size: 2.25rem;
  }
}

.body-featured {
  font-size: 1.3125rem;
}
.body-featured:not(:last-of-type) {
  margin-bottom: 24px;
}

.sharped {
  font-family: var(--font-secondary);
  font-weight: 800;
}

.is-simple-link {
  color: inherit;
}

.has-accent-fill {
  color: var(--color-accent);
}

@media (prefers-color-scheme: dark) {
  :root {
    --color-accent: #7D39F8;
    --color-accent-light: rgba(125, 57, 248, .1);
    --color-black: #fff;
    --color-white: #000;
    --color-light-gray: #353535;
    --color-gray: #4f4f4f;
    --background-page: #232323;
    --background-bento-item: linear-gradient(180deg, rgba(211, 184, 184, 0.05) 0%, rgba(234, 234, 234, 0.25) 100%);
  }
}
.main-header {
  padding: 21px 15% 0 15%;
  text-align: center;
}

.main-logo {
  max-width: 100%;
}

.main-footer {
  margin-top: 120px;
}

.main-footer__content {
  align-items: center;
  border-top: 1px solid var(--color-gray);
  display: flex;
  font-size: 0.875rem;
  justify-content: space-between;
  padding: 21px 12px;
}

.main-footer__menu {
  align-items: center;
  display: flex;
  gap: 9px;
}

.main-footer__menu-item {
  list-style: none;
}