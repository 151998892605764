html{
  font-size: 100%;
}

body{
  background-color: var(--background-page);
  color: var(--color-black);
  font-family: var(--font-primary);
  font-weight: 400;
  line-height: 1.3;
}

h1, h2, h3{
  font-weight: 900;
}

strong{
  font-weight: 600;
}

.visually-hidden{
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  width: 1px;
}

.box-content{
  padding-left: 2%;
  padding-right: 2%;
}

.main-title {
  font-size: 2.625rem;

  @media (min-width: 768px) {
    font-size: 3rem;
  }
}

.main-subtitle{
  font-size: 1.5rem;
  font-weight: 400;

  @media (min-width: 768px) {
    font-size: 2.25rem;
  }
}

.body-featured{
  font-size: 1.3125rem;

  &:not(:last-of-type){
    margin-bottom: 24px;
  }
}

.sharped{
  font-family: var(--font-secondary);
  font-weight: 800;
}

.is-simple-link{
  color: inherit;
}

.has-accent-fill{
  color: var(--color-accent);
}

//@media (prefers-color-scheme: dark) {
//  :root{
//    --color-accent: #7D39F8;
//    --color-accent-light: rgba(125, 57, 248, .1);
//    --color-black: #fff;
//    --color-white: #000;
//    --color-light-gray: #353535;
//    --color-gray: #4f4f4f;
//
//    --background-page: #232323;
//    --background-bento-item: linear-gradient(180deg, rgba(211, 184, 184, 0.05) 0%, rgba(234, 234, 234, 0.25) 100%);
//  }
//}
