.button{
  background-color: var(--color-accent);
  border-radius: 6px;
  box-shadow: 0 0 0 0 var(--color-black);
  color: var(--color-black);
  display: inline-block;
  font-size: 1.1875rem;
  font-style: italic;
  font-weight: 900;
  line-height: 1;
  padding: 12px 24px;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  transition: box-shadow .2s;

  &:hover,
  &:focus{
    box-shadow: 0 4px 0 0 var(--color-black);
  }
}

.button--dark{
  background-color: var(--color-black);
  border-color: var(--color-black);
  color: var(--color-white);
}

.button--full{
  display: block;
}